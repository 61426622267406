exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-templates-category-archive-index-tsx": () => import("./../../../src/templates/Category/Archive/index.tsx" /* webpackChunkName: "component---src-templates-category-archive-index-tsx" */),
  "component---src-templates-life-archive-index-tsx": () => import("./../../../src/templates/Life/Archive/index.tsx" /* webpackChunkName: "component---src-templates-life-archive-index-tsx" */),
  "component---src-templates-life-single-index-tsx": () => import("./../../../src/templates/Life/Single/index.tsx" /* webpackChunkName: "component---src-templates-life-single-index-tsx" */),
  "component---src-templates-page-single-index-tsx": () => import("./../../../src/templates/Page/Single/index.tsx" /* webpackChunkName: "component---src-templates-page-single-index-tsx" */),
  "component---src-templates-post-archive-index-tsx": () => import("./../../../src/templates/Post/Archive/index.tsx" /* webpackChunkName: "component---src-templates-post-archive-index-tsx" */),
  "component---src-templates-post-single-index-tsx": () => import("./../../../src/templates/Post/Single/index.tsx" /* webpackChunkName: "component---src-templates-post-single-index-tsx" */)
}

